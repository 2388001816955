import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { PrimaryBtn } from '../components/Buttons/Primary';
import { SecondaryBtn } from '../components/Buttons/Secondary';
import useWindowSize from '../hooks/useWindowSize';
import { h3 } from './Headings';

const Box2 = styled.div`
    max-width: 1050px;

    @media (min-width: 768px) {
        display: flex;
        margin: 0 auto;
        filter: drop-shadow(0px 32px 64px rgba(33, 163, 185, 0.2));
        max-width: 815px;
    }

    @media (min-width: 1280px) {
        position: relative;

        ::before {
            display: ${({ invisalignPage }) => (invisalignPage ? 'none' : 'block')};
            content: '';
            position: absolute;
            left: -128px;
            top: -128px;
            border: 8px solid ${({ theme }) => theme.lightTeal};
            border-top-right-radius: 64px;
            border-bottom-left-radius: 64px;
            width: 256px;
            height: 256px;
            z-index: -1;
        }

        ::after {
            display: ${({ invisalignPage }) => (invisalignPage ? 'none' : 'block')};
            content: '';
            position: absolute;
            right: -128px;
            bottom: -128px;
            border: 8px solid ${({ theme }) => theme.lightTeal};
            border-top-left-radius: 64px;
            border-bottom-right-radius: 64px;
            width: 256px;
            height: 256px;
            z-index: -1;
        }
    }

    @media (min-width: 1600px) {
        max-width: 1050px;
    }
`;

const Smile = styled.div`
    padding: 32px;
    background: ${({ theme }) => theme.darkTeal};
    border-top-left-radius: 64px;
    max-width: 500px;

    h2 {
        margin: 0 0 32px;
    }

    p {
        margin: 0;
    }
    a.learn-more-btn {
        :not(:hover) {
            border-color: white;
        }
    }

    a:last-of-type {
        margin-top: 16px;
    }

    a:first-of-type {
        margin-top: 32px;
    }

    h2,
    p,
    a {
        color: ${({ theme }) => theme.white};
    }

    @media (min-width: 768px) {
        width: 50%;
    }

    @media (min-width: 1600px) {
        width: 100%;
        max-width: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px 32px 64px 48px;

        h2 {
            margin-bottom: 0;
        }

        p {
            ${h3};
        }
    }
`;

export default function InvisalignBox({ invisalignPage }) {
    const size = useWindowSize();

    return (
        <>
            <Box2 invisalignPage={invisalignPage}>
                <Smile>
                    <h2>Your confident smile starts here</h2>
                    <p>
                        Dr. Herbert Land is a trained Invisalign specialist serving Christiansted.
                    </p>
                    <div>
                        {invisalignPage ? (
                            ''
                        ) : (
                            <SecondaryBtn as={Link} to="/invisalign/" className="learn-more-btn">
                                Learn about invisalign
                            </SecondaryBtn>
                        )}
                        <PrimaryBtn as="a" href="#contact-us">
                            Book An Appointment
                        </PrimaryBtn>
                    </div>
                </Smile>
                <StaticImage
                    src="../images/invisalign/home-child.jpg"
                    alt="child with invisalign"
                    css={css`
                        border-bottom-right-radius: 64px;
                        height: 500px;

                        @media (min-width: 768px) {
                            height: auto;
                            border-top-right-radius: 64px;
                            width: 50%;
                        }
                    `}
                />
            </Box2>
            {size.width >= 1366 && (
                <>
                    <StaticImage
                        src="../images/invisalign/teeth.png"
                        alt="invisalign background"
                        width={450}
                        height={642}
                        layout="fixed"
                        css={css`
                            position: absolute;
                            top: 50%;
                            left: -10px;
                            transform: translateY(-50%);
                            display: none;
                            width: 290px !important;
                            height: 418px !important;

                            @media (min-width: 1366px) {
                                display: block;
                            }

                            @media (min-width: 1800px) {
                                width: 370px !important;
                                height: 530px !important;
                            }

                            @media (min-width: 1900px) {
                                width: 450px !important;
                                height: 642px !important;
                            }
                        `}
                    />
                    <StaticImage
                        src="../images/invisalign/teeth-1.png"
                        alt="invisalign background"
                        width={402}
                        height={692}
                        layout="fixed"
                        css={css`
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            display: none;
                            width: 268px !important;
                            height: 461px !important;

                            @media (min-width: 1366px) {
                                display: block;
                            }

                            @media (min-width: 1800px) {
                                width: 335px !important;
                                height: 576px !important;
                            }

                            @media (min-width: 1900px) {
                                width: 402px !important;
                                height: 692px !important;
                            }
                        `}
                    />
                </>
            )}
        </>
    );
}
